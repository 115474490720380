// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("/opt/build/repo/src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-custom-shipping-containers-js": () => import("/opt/build/repo/src/pages/custom-shipping-containers.js" /* webpackChunkName: "component---src-pages-custom-shipping-containers-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prairie-wood-products-js": () => import("/opt/build/repo/src/pages/prairie-wood-products.js" /* webpackChunkName: "component---src-pages-prairie-wood-products-js" */)
}

